<template>
  <div class="book">
    <span>图书ID：{{ bid }}</span>
    <template v-if="book">
      <div class="">图书名称：{{ book.bookName }}</div>
    </template>
  </div>
</template>

<script>
import qs from "qs";
import { Dialog } from "vant";

export default {
  name: "book",
  data() {
    return {
      bid: this.$route.query.bid,
      fid: this.$route.query.fid,
      rid: this.$route.query.rid,
      book: "",
    };
  },
  watch: {},
  mounted() {
    this.getBook();
  },
  methods: {
    getBook() {
      //432183365714579456
      let params = {
        bid: this.bid,
      };
      this.$api.mary.GetBookInfo(params).then((res) => {
        let { tag, message, data } = res;
        if (tag == 1) {
          this.book = data;
        } else {
          Dialog.alert({
            message: message,
          });
        }
        //console.log(res);
      });
    },
    getFolder() {
      //432183517217034240
      let params = {
        bid: this.bid,
        fid: this.fid,
      };
      this.$api.mary.getFolder(params).then((res) => {
        let { tag, message, data } = res;
        if (tag == 1) {
          this.book = data;
        } else {
          Dialog.alert({
            message: message,
          });
        }
        //console.log(res);
      });
    },
    getRes() {
      //432183731076206592
      let params = {
        bid: this.bid,
        fid: this.fid,
        rid: this.rid,
      };
      this.$api.mary.getRes(params).then((res) => {
        let { tag, message, data } = res;
        if (tag == 1) {
          this.book = data;
        } else {
          Dialog.alert({
            message: message,
          });
        }
        //console.log(res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.book {
  &-title {
    color: red;
  }
}
</style>
